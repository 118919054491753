export default function InquiryForm() {
    return {
        inquiry_type: 'make_offer', // make_offer | contact_owner | contact_seller
        show_make_offer: document.getElementById('show_make_offer').value === '1',
        email_verification: document.getElementById('email_verification').value === '1',
        inquiry_path: document.getElementById('inquiry_path').value,
        captcha_path: document.getElementById('captcha_path').value,

        // parking_container: document.getElementById('parking_container'),
        inquiry_container: document.getElementById('inquiry_container'),
        form_container: document.getElementById('inquiry_form_container'),
        success_container: document.getElementById('inquiry_success'),
        form: document.getElementById('inquiry_form'),
        form_submit: document.getElementById('submit'),
        form_fields: [
            'inquiry_first_name',
            'inquiry_last_name',
            'inquiry_email',
            'inquiry_country',
            'inquiry_phone',
            'inquiry_offer',
            'inquiry_message',
            'inquiry_captcha',
        ],
        error_classes: ['has-error', '!border-red-400', 'zfocus:!ring-red-400', 'focus:!ring-opacity-80', 'focus:!border-red-50'],
        init() {
            // console.log('InquiryForm: init', {
            //     show_make_offer: this.show_make_offer,
            //     email_verification: this.email_verification,
            //     inquiry_path: this.inquiry_path,
            //     captcha_path: this.captcha_path,
            // });
        },
        refreshCaptcha() {
            let captcha_img = document.getElementById('captcha_img'),
                captcha_key = document.getElementById('captcha_key');
            // reset to default while loading
            captcha_img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
            axios.get(this.captcha_path)
                .then(response => {
                    if (response.data.hasOwnProperty('key') && response.data.hasOwnProperty('img')) {
                        // console.log('refreshCaptcha', response.data['key'], response.data['img']);
                        captcha_key.value = response.data['key'];
                        captcha_img.src = response.data['img'];
                    }
                });
        },
        openFormCallback() {},
        openForm(inquiry_params) {
            if (inquiry_params && inquiry_params.hasOwnProperty('button')) {
                if (inquiry_params.button === 'make_offer_button') {
                    this.inquiry_type ='make_offer';
                } else if (inquiry_params.button === 'contact_owner_button') {
                    this.inquiry_type ='contact_owner';
                } else if (inquiry_params.button === 'contact_seller_button') {
                    this.inquiry_type = 'contact_seller';
                }
            }

            // disable animation
            this.disableAnimation();

            // remove loading state
            this.showLoading(false);

            // refresh captcha
            this.refreshCaptcha();

            // set form title
            if (this.inquiry_type === 'make_offer') {
                document.getElementById('inquiry_form_type').value = this.inquiry_type;
                document.getElementById('inquiry_form_title').textContent = 'Make an Offer';
                document.getElementById('inquiry_form_recipient').textContent = 'seller';
                document.getElementById('inquiry_offer_block').classList.remove('hidden');
                document.getElementById('inquiry_offer_block').classList.add('flex');
                document.getElementById('submit').textContent = 'Submit Offer';
            } else if (this.inquiry_type === 'contact_owner') {
                document.getElementById('inquiry_form_type').value = 'contact_owner';
                document.getElementById('inquiry_form_title').textContent = 'Contact Owner';
                document.getElementById('inquiry_form_recipient').textContent = 'owner';
                document.getElementById('inquiry_offer_block').classList.add('hidden');
                document.getElementById('inquiry_offer_block').classList.remove('flex');
                document.getElementById('submit').textContent = 'Send Inquiry';
            } else if (this.inquiry_type === 'contact_seller') {
                document.getElementById('inquiry_form_type').value = 'contact_seller';
                document.getElementById('inquiry_form_title').textContent = 'Contact Seller';
                document.getElementById('inquiry_form_recipient').textContent = 'seller';
                document.getElementById('inquiry_offer_block').classList.add('hidden');
                document.getElementById('inquiry_offer_block').classList.remove('flex');
                document.getElementById('submit').textContent = 'Send Message';
            } else {
                throw new Error('Invalid Inquiry Type');
            }

            // expand page height so form is closer to the top
            // this.parking_container.classList.add('mb-[500px]')
            this.openFormCallback();
            document.getElementById('inquiry_captcha').value = '';
            this.success_container.classList.add('hidden');
            this.inquiry_container.classList.remove('hidden');
            this.form_container.classList.add('opacity-0');
            this.form_container.classList.add('mt-[-18rem]');
            this.form_container.classList.remove('hidden');
            setTimeout(() => {
                this.form_container.classList.add('duration-200');
                this.form_container.classList.add('transition-all');
                this.form_container.classList.add('ease-in-out');
                this.form_container.classList.remove('mt-[-18rem]');
                this.form_container.classList.remove('opacity-0');
                this.form_container.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    // focus the name field after scroll
                    document.getElementById('inquiry_first_name').focus();
                }, 450);
            }, 1);
        },
        closeFormCallback() {},
        closeForm() {
            // hide form and success
            this.inquiry_container.classList.add('hidden');
            this.form_container.classList.add('hidden');
            this.success_container.classList.add('hidden');
            this.closeFormCallback();
            // this.parking_container.classList.remove('mb-[500px]');
            window.scrollTo({top: 0, behavior: 'smooth'});
            // wait for transition to finish before hiding
            setTimeout(() => {
                this.form.reset();
                this.clearErrors();
                this.showLoading(false);
            }, 100);
        },
        disableAnimation() {
            this.form_container.classList.remove('duration-200');
            this.form_container.classList.remove('transition-all');
            this.form_container.classList.remove('ease-in-out');
            this.success_container.classList.remove('duration-100');
            this.success_container.classList.remove('transition-all');
            this.success_container.classList.remove('ease-in-out');
        },
        showSuccess() {
            this.disableAnimation();

            // show success message
            let successTitles =  {
                'make_offer': 'Your offer has been sent!',
                'contact_owner': 'Your inquiry has been sent!',
                'contact_seller': 'Your message has been sent!',
            };
            let successTitle = successTitles[this.inquiry_type];

            let successMessages = {
                'make_offer': 'We have forwarded your offer to the seller.',
                'contact_owner': 'We have forwarded your details to the owner.',
                'contact_seller': 'We have forwarded your message to the seller.',
            }
            let successMessage = successMessages[this.inquiry_type];

            // if email verification is enabled, show message to confirm email
            if (this.email_verification) {
                // console.log('EMAIL VERIFICATION: '+(this.email_verification ? 'ON' : 'OFF'));
                successTitle = 'Please confirm your email.';
                successMessage = 'We\'ve sent a verification link to your email address. <br/> Check your inbox and click the link to send your inquiry.';

                // successTitles =  {
                //     'make_offer': 'Please confirm your email.',
                //     'contact_owner': 'Please confirm your email.',
                //     'contact_seller': 'Please confirm your email.',
                // };
                // successMessages = {
                //     'make_offer': 'Please check your email to verify.',
                //     'contact_owner': 'Please check your email to verify.',
                //     'contact_seller': 'Please check your email to verify.',
                // }
            }

            document.getElementById('inquiry_success_title').textContent = successTitle;
            document.getElementById('inquiry_success_body').innerHTML = successMessage;

            this.success_container.classList.add('opacity-0');
            this.success_container.classList.remove('hidden');
            setTimeout(() => {
                this.success_container.classList.add('duration-100');
                this.success_container.classList.add('transition-all');
                this.success_container.classList.add('ease-in-out');
                this.success_container.classList.remove('opacity-0');
                // this.success_container.classList.remove('mt-[-18rem]');
                setTimeout(() => {
                    // bounce check once
                    let success_check = document.getElementById('inquiry_success_check');
                    success_check.classList.remove('bounce-once');
                    // success_check.offsetWidth = success_check.offsetWidth;
                    success_check.classList.add('bounce-once');
                }, 100);
            }, 100);
        },
        showLoading(loading) {
            if (loading) {
                this.disableAnimation();
                this.form_submit.disabled = true;
                this.form_container.classList.add('opacity-60');
                this.form_container.classList.add('cursor-not-allowed');
                this.form_container.classList.add('pointer-events-none');
                this.inquiry_container.classList.add('bg-gray-50');
            } else {
                this.form_submit.disabled = false;
                this.form_container.classList.remove('opacity-60');
                this.form_container.classList.remove('cursor-not-allowed');
                this.form_container.classList.remove('pointer-events-none');
                this.inquiry_container.classList.remove('bg-gray-50');
            }
        },
        submitForm(event) {
            event.preventDefault();
            let form_data = new FormData(event.target),
                form_values = Object.fromEntries(form_data.entries()),
                form_action = event.target.action;
            // console.log(event, form_data, form_values, form_action);

            // loading
            this.showLoading(true);
            this.clearErrors();

            // submit xhr-protected inquiry
            axios.post(form_action, form_data)
                .then(response => {
                    if (response.data.hasOwnProperty('success') && response.data.success) {
                        console.log(response.data.message);
                        this.showSuccess();
                    } else {
                        // remove loading state
                        this.showLoading(false);
                        console.error('unsuccessful', response);
                        alert('Error sending inquiry, please try again.');
                    }
                })
                .catch(error => {
                    // remove loading state
                    this.showLoading(false);

                    if (error.response.data.hasOwnProperty('errors')) {
                        // validation errors
                        this.showErrors(error.response.data.errors);
                    } else {
                        // network or security error
                        console.error('Error processing request', error);
                        alert('Error processing request, please try again.');
                    }
                });
        },
        showErrors(errors) {
            // console.error('validation errors', errors);
            this.form_fields.forEach((field, _) => {
                let field_el = document.getElementById(field),
                    error_el = document.getElementById(field + '_error');
                // show error for field
                if (errors.hasOwnProperty(field)) {
                    let error_msg = errors[field][0];
                    if (field_el) {
                        field_el.classList.add.apply(
                            field_el.classList,
                            this.error_classes
                        );
                    }
                    if (error_el) {
                        if (error_el.classList.contains('hidden')) {
                            error_el.classList.remove('hidden');
                        }
                        error_el.innerHTML = error_msg;
                    }
                    if (field === 'inquiry_captcha') {
                        // invalid captcha, refresh captcha to try again
                        console.error('Invalid captcha response');
                        field_el.value = '';
                        this.refreshCaptcha();
                    }
                    // clear error for field
                } else {
                    if (field_el) {
                        field_el.classList.remove.apply(
                            field_el.classList,
                            this.error_classes
                        );
                    }
                    if (error_el) {
                        error_el.classList.add('hidden');
                        error_el.innerText = '';
                    }
                }
            });
        },
        clearErrors() {
            let error_messages = document.querySelectorAll('.form-group-error');
            error_messages.forEach((el) => {
                if (!el.classList.contains('hidden')) {
                    el.classList.add('hidden');
                    el.innerText = '';
                }
            });
            let error_fields = document.querySelectorAll('.has-error');
            error_fields.forEach((el) => {
                el.classList.remove.apply(
                    el.classList,
                    this.error_classes
                );
            });
        },
    }
}
